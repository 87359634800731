import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import React, { useState } from 'react';
import { useAuth } from '../auth/useAuth';
import { axiosInstance } from '../auth/axios';
import { useNavigate } from 'react-router-dom';
import { isStrongPassword } from 'validator';
import { alerts } from '../AlertProvider';
import { AxiosError } from 'axios';
import Skeleton from '@mui/joy/Skeleton';
import CircularProgress from '@mui/joy/CircularProgress';

type Profile = {
  [index: string]: string;

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const SkeletonComponent = () => (
  <Box>
    <Box sx={{ px: { xs: 2, md: 6 } }}>
      <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
        Profile
      </Typography>
    </Box>
    <Stack
      sx={{
        display: 'flex',
        maxWidth: '800px',
        px: { xs: 2, md: 6 },
        py: { xs: 2, md: 3 }
      }}
    >
      <Card>
        <Stack spacing={2}></Stack>
        <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
        <CardActions>
          <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
          <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
        </CardActions>
      </Card>
    </Stack>
  </Box>
);

export default function Password() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [newError, setNewError] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const [currPwError, setCurrPwError] = useState(false);
  const [helperNewText, setHelperNewText] = useState('');
  const [helperConfirmText, setHelperConfirmText] = useState('');
  const [helperCurrText, setHelperCurrText] = useState('');
  const [profile, setProfile] = useState<Profile>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const passwordOpts = {
    minLength: 6,
    minLowercase: 0,
    minUppercase: 0,
    minNumbers: 0,
    minSymbols: 0,
    // returnScore cannot be true because it will change the return type
    returnScore: false,
    pointsPerUnique: 1,
    pointsPerRepeat: 0.5,
    pointsForContainingLower: 10,
    pointsForContainingUpper: 10,
    pointsForContainingNumber: 10,
    pointsForContainingSymbol: 10
  } as const;

  if (!user) {
    return <SkeletonComponent />;
  }

  const changePasswordUrl = '/api/users/';

  const submitChangePassword = async (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    setSubmitting(true);

    const formElements = event.currentTarget.elements;
    validateConfirmPassword(
      formElements.newPassword.value,
      formElements.confirmPassword.value
    );

    if (newError || confirmError) {
      return;
    }

    const body = {
      currentPassword: formElements.currentPassword.value,
      newPassword: formElements.newPassword.value
    };

    await axiosInstance
      .patch(changePasswordUrl + user.id + '/password', body)
      .then((res) => {
        alerts.show &&
          alerts.show('primary', 'Password changed successfully!', 3000);
        setProfile({
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      })
      .catch((e: AxiosError<{ message: string; error: string }>) => {
        setProfile({
          ...profile,
          currentPassword: ''
        });
        const { response } = e;
        if (
          // In case of 'incorrect password' only
          response?.data?.error === 'Bad request' &&
          response?.data?.message === 'Current password is incorrect!'
        ) {
          setCurrPwError(true);
          setHelperCurrText(response.data.message);
          alerts.show && alerts.show('danger', response.data.message, 3000);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validateNewPassword = (password: string) => {
    if (!isStrongPassword(password, passwordOpts)) {
      setNewError(true);
      setHelperNewText('Password must be at least 6 characters long.');
    } else {
      setNewError(false);
      setHelperNewText('');
    }
  };

  const validateConfirmPassword = (
    newPassword: string,
    confirmPassword: string
  ) => {
    if (newPassword !== confirmPassword) {
      setConfirmError(true);
      setHelperConfirmText('Passwords do not match!');
    } else {
      resetConfirmError();
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const key = e.target.name;
    const value = e.target.value;
    setProfile({
      ...profile,
      [key]: value
    });

    if (key === 'currentPassword') {
      resetCurrentError();
      setProfile({
        ...profile,
        [key]: value
      });
      return;
    }

    setTypingTimeout(
      setTimeout(() => {
        if (key === 'newPassword') {
          validateNewPassword(value);
        } else {
          validateConfirmPassword(profile['newPassword'], value);
        }
      }, 700)
    );
  };

  const resetConfirmError = () => {
    setConfirmError(false);
    setHelperConfirmText('');
  };

  const resetCurrentError = () => {
    setCurrPwError(false);
    setHelperCurrText('');
  };

  return (
    <Box>
      <Box sx={{ px: { xs: 2, md: 6 } }}>
        <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
          Change password
        </Typography>
      </Box>
      <Stack
        sx={{
          display: 'flex',
          maxWidth: '800px',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 }
        }}
      >
        <Card>
          <form id="passwordForm" onSubmit={submitChangePassword}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Current password</FormLabel>
                <Input
                  size="sm"
                  name="currentPassword"
                  type="password"
                  onChange={handlePasswordChange}
                  value={profile?.currentPassword || ''}
                ></Input>
                {helperCurrText && (
                  <Typography
                    level="body-sm"
                    color={currPwError ? 'danger' : 'neutral'}
                  >
                    {helperCurrText}
                  </Typography>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>New password</FormLabel>
                <Input
                  size="sm"
                  name="newPassword"
                  type="password"
                  onChange={handlePasswordChange}
                  value={profile?.newPassword || ''}
                ></Input>
                {helperNewText && (
                  <Typography
                    level="body-sm"
                    color={newError ? 'danger' : 'neutral'}
                  >
                    {helperNewText}
                  </Typography>
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Confirm new password</FormLabel>
                <Input
                  size="sm"
                  name="confirmPassword"
                  type="password"
                  onChange={handlePasswordChange}
                  value={profile?.confirmPassword || ''}
                ></Input>
              </FormControl>
              {helperConfirmText && (
                <Typography
                  level="body-sm"
                  color={confirmError ? 'danger' : 'neutral'}
                >
                  {helperConfirmText}
                </Typography>
              )}
              <CardActions
                sx={{
                  justifyContent: 'flex-end',
                  alignSelf: 'flex-end',
                  pt: 2
                }}
              >
                <Button
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  onClick={() => navigate('/profile')}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant="solid"
                  type="submit"
                  disabled={
                    newError || currPwError || confirmError || submitting
                  }
                >
                  {submitting ? (
                    <CircularProgress size="sm" sx={{ color: 'white' }} />
                  ) : (
                    'Update'
                  )}
                </Button>
              </CardActions>
            </Stack>
          </form>
        </Card>
      </Stack>
    </Box>
  );
}
