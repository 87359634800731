import * as React from 'react';
import Box from '@mui/joy/Box';
import Sidebar from '../components/Sidebar';
import { Navigate, Route, Routes } from 'react-router-dom';
import MaterialsList from '../MaterialsList';
import CreateMaterialForm from '../components/CreateMaterialForm';
import { LocationAwareBreadcrums } from '../components/LocationAwareBreadcrums';
import { CssBaseline, CssVarsProvider } from '@mui/joy';
import Header from '../components/Header';
import { AlertProvider } from '../AlertProvider';
import EditUserInfoForm from './EditUserInfoForm';
import Password from './Password';

type Props = {};

export const Main = (props: Props) => {
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'inline-flex' }}>
        <Header />
        <Sidebar />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3
            },
            pb: { xs: 2, sm: 2, md: 3 },
            height: '100dvh',
            gap: 1
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              padding: '16px',
              width: { sm: '100%', md: 'calc(100% - 240px)' },
              left: { sm: '0px', md: '240px' },
              top: { sm: '26px', md: '16px' }
            }}
          >
            <LocationAwareBreadcrums />
            <Routes>
              <Route path="/" element={<Navigate to="/materials" />} />
              <Route
                path="/materials"
                element={<MaterialsList></MaterialsList>}
              />
              <Route path="/materials/new" element={<CreateMaterialForm />} />
              <Route path="/profile" element={<EditUserInfoForm />} />
              <Route path="/profile/password" element={<Password />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
};
