import * as React from 'react';
import { Box, Breadcrumbs, CssBaseline, CssVarsProvider } from '@mui/joy';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import MaterialTable from './components/MaterialTable';

export default function MaterialsList() {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <Typography level="h2" component="h1">
          Materials
        </Typography>
      </Box>
      <MaterialTable pageLimit={10} />
    </Box>
  );
}
