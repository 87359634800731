import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import { Main } from './pages/Main';
import { AuthProvider } from './auth/useAuth';
import NavProvider from './NavProvider';
import { AlertProvider } from './AlertProvider';
import { Box, Card, Typography } from '@mui/joy';
import config from './config';

export default function MainLayout() {
  React.useEffect(() => {
    document.title = `Adbot | ${config.CLIENT_NAME}`;
  }, []);
  if (config.ENABLE_DEBUG) console.log('IN DEBUG MODE');
  return (
    <BrowserRouter>
      <AuthProvider>
        <NavProvider>
          <AlertProvider>
            <CssVarsProvider disableTransitionOnChange>
              <CssBaseline />
              {!!config.ENABLE_DEBUG && (
                <Box
                  sx={{
                    zIndex: 9999,
                    position: 'fixed',
                    top: 10,
                    right: 10,
                    backgroundColor: 'rgba(255, 99, 71, 0.4)'
                  }}
                >
                  <Typography fontSize={20}>Debug</Typography>
                </Box>
              )}
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Main />}></Route>
              </Routes>
            </CssVarsProvider>
          </AlertProvider>
        </NavProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
