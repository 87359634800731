import { Alert, Box, ColorPaletteProp } from '@mui/joy';
import React, { PropsWithChildren, useEffect, useState } from 'react';

type Props = {};

export const alerts: { show?: Function } = {};

export const AlertProvider = ({ children }: PropsWithChildren) => {
  const [color, setColor] = useState<ColorPaletteProp>('primary');
  const [content, setContent] = useState<React.ReactNode>();

  const [opacity, setOpacity] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const [timeOuts, setTimeouts] = useState<NodeJS.Timeout[]>();

  const showAlert = (
    color: ColorPaletteProp,
    content: React.ReactNode,
    time: number
  ) => {
    timeOuts?.forEach((timeout) => clearTimeout(timeout));

    setIsVisible(true);
    setColor(color);
    setContent(content);
    setTimeouts([
      setTimeout(() => {
        setOpacity(100);
      }, 10),
      setTimeout(() => {
        setOpacity(0);
      }, time),
      setTimeout(() => {
        setIsVisible(false);
      }, time + 500)
    ]);
  };
  alerts.show = showAlert;
  return (
    <>
      <Box
        sx={{
          bottom: 0,
          position: 'fixed',
          zIndex: 9999,
          width: '100%',
          display: isVisible ? 'flex' : 'none'
        }}
      >
        <Alert
          color={color}
          variant="outlined"
          sx={{
            marginBottom: '10%',
            marginInline: 'auto',
            opacity,
            transition: opacity === 100 ? 'opacity .2s' : 'opacity .5s'
          }}
        >
          {content}
        </Alert>
      </Box>
      {children}
    </>
  );
};
