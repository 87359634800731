import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/useAuth';
import { axiosInstance } from '../auth/axios';
import { useNavigate } from 'react-router-dom';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import Skeleton from '@mui/joy/Skeleton';
import CircularProgress from '@mui/joy/CircularProgress';
import validator from 'validator';

export default function EditUserInfoForm() {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const patchUserUrl = '/api/users/';

  useEffect(() => {
    if (!user) {
      return navigate('/login');
    }
    setUserId(user.id);
    setUsername(user.name);
    setRole(user.role);
    setEmail(user.email);
    setLoading(false);
  }, []);

  const saveProfileInfo = async (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    setSubmitting(true);

    const body = { name: username, email };
    await axiosInstance
      .patch(patchUserUrl + userId, body)
      .then((res) => {
        if (user) {
          setUser({ ...user, email });
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setSubmitting(false));
  };

  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995
        }}
      >
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
            Profile
          </Typography>
        </Box>
      </Box>
      <Stack
        sx={{
          display: 'flex',
          maxWidth: '800px',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 }
        }}
      >
        {loading ? (
          <Card>
            <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
            <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
            <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
          </Card>
        ) : (
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Personal info</Typography>
            </Box>

            <form id="profileForm" onSubmit={saveProfileInfo}>
              <Stack spacing={2} sx={{ display: 'flex', my: 0 }}>
                <FormControl>
                  <FormLabel>Username</FormLabel>
                  <Input
                    variant="plain"
                    type="text"
                    name="username"
                    value={username}
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    size="sm"
                    type="email"
                    placeholder="example@email.com"
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!email && !validator.isEmail(email || '')}
                    value={email}
                  />
                </FormControl>
                {!!email && !validator.isEmail(email || '') && (
                  <Typography level="body-sm" color={'danger'}>
                    Your email is invalid!
                  </Typography>
                )}
                <FormControl>
                  <Button
                    size="sm"
                    variant="solid"
                    sx={{ width: '150px' }}
                    onClick={() => navigate('/profile/password')}
                  >
                    Change password
                  </Button>
                </FormControl>
              </Stack>
            </form>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 0 }}>
              <Button
                onClick={() => navigate('/')}
                size="sm"
                variant="outlined"
                color="neutral"
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="solid"
                type="submit"
                form="profileForm"
                disabled={submitting}
              >
                {submitting ? (
                  <CircularProgress size="sm" sx={{ color: 'white' }} />
                ) : (
                  'Save'
                )}
              </Button>
            </CardActions>
          </Card>
        )}
      </Stack>
    </Box>
  );
}
