import axios from 'axios';
import globalRouter from '../globalRouter';
import config from '../config';

const axiosConfig = {
  baseURL: config.BACKEND_URL,
  timeout: 30000,
  withCredentials: true
};

export const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.response.use(
  (response) => {
    if (!response.config.url?.includes('/auth/refresh')) {
      axiosInstance.get('/auth/refresh');
    }
    return response;
  },
  (error) => {
    if (
      error.response.status == 401 &&
      globalRouter.navigate &&
      !error.response.config.url?.includes('/auth/login')
    ) {
      globalRouter.navigate('/login');
    } else {
      return Promise.reject(error);
    }
  }
);
