import { Box, CircularProgress } from '@mui/joy';
import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type Props = {
  src?: string;
  loading?: boolean;
  imgStyle: any;
  fail?: boolean;
};

export const PreviewImage = (props: Props) => {
  return (
    <>
      <img
        style={{
          ...props.imgStyle
        }}
        src={props.src}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          opacity: props.loading ? 0.2 : 0,
          transition: 'opacity 1s'
        }}
      ></Box>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%' }}>
        <CircularProgress
          sx={{
            opacity: props.loading ? 1 : 0,
            transition: 'opacity 1s',
            ml: '-50%',
            mt: '-50%'
          }}
        />
      </Box>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%' }}>
        <ErrorOutlineIcon
          fontSize="large"
          sx={{
            opacity: props.fail ? 1 : 0,
            transition: 'opacity 1s',
            ml: '-50%',
            mt: '-50%'
          }}
        />
      </Box>
    </>
  );
};
